import React from "react";
import { injectIntl } from 'gatsby-plugin-intl';

import "../styles/contact-form.less";


class ContactForm extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            name: {
                error: false,
                errorMsg: '',
                value: ''
            },
            phone: {
                error: false,
                errorMsg: '',
                value: ''
            },
            email: {
                error: false,
                errorMsg: '',
                value: ''
            },
            subject: {
                error: false,
                errorMsg: '',
                value: ''
            },
            message: {
                error: false,
                errorMsg: '',
                value: ''
            },
            messageStatus: null
        };

        this.send = this.send.bind(this);
    }

    handleChange(event, field)
    {
        let newState = {...this.state, [field]: {...this.state[field], value: event.target.value}}
        this.setState(newState);
    }

    send()
    {
        var button = document.querySelector('.form-trigger button');
        button.disabled = true;

        if (this.validate())
        {
            const url = 'http://scripts.localhost'; // Update this to the final api endpoint
            const formData = this.state;

            fetch(
                url, 
                {
                    method: 'POST',
                    crossDomain: true,
                    body: JSON.stringify(formData),
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then(res => res.json())
            .then(
                response => {
                    // Aquí entrará aunque la respuesta sea un error 404 o un error 500, así que hay que gestionar los distintos casos para mostrar un mensaje u otro
                    // Pero hay que hacerlo dentro de este if, porque al parecer entra en el catch y también en el then cuando hay errores graves (no he conseguido solucionarlo aún)
                    if (response)
                    {
                        if (!response.result)
                        {
                            this.props.displayFlashNotification('err');
                        }
                        else
                        {
                            this.props.displayFlashNotification('ok');
                        }
                    }
                }
            )
            .catch(
                (err) => {
                    // Aquí solo debería entrar en caso de error que impida que la función se ejecute (error de red, o etc)
                    this.props.displayFlashNotification('err')
                }
            )
            
        }
        else
        {
            button.disabled = false;
        }
    }

    validate()
    {
        let fields = [];
        let errors = 0;
        Object.keys(this.state).forEach((field) => {
            if (field === 'messageStatus')
            {
                return;
            }
            if (this.state[field].value.length === 0)
            {
                errors++;
                fields.push({key: field, error: true, errorMsg: `'${field}' cannot be empty`, value: this.state[field].value});
            }
            else
            {
                fields.push({key: field, error: false, errorMsg: '', value: this.state[field].value});
            }
        });

        let newState = {};
            
        fields.forEach((field) => {
            newState[field.key] = {error: field.error, errorMsg: field.errorMsg, value: field.value};
        });

        this.setState( {...newState } );

        if (errors > 0)
        {
            return false;
        }
        return true;
    }

    render()
    {
        return <form className="form-contact">
            <h3 className="title">{this.props.intl.formatMessage({id: 'Write us'})}</h3>
            <div className="row">
                <div className="col-lg-4">
                    <div className={"field " + (this.state.name.error && "has-error")}>
                        <input onChange={(event) => this.handleChange(event, 'name')} className="form-control" type="text" name="ContactForm[name]" placeholder={this.props.intl.formatMessage({id: "Name"})} aria-required="true" value={this.state.name.value} />
                        {this.state.name.error && <p className="help-block help-block-error">{this.props.intl.formatMessage({id: this.state.name.errorMsg})}</p>}
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className={"field " + (this.state.email.error && "has-error")}>
                        <input onChange={(event) => this.handleChange(event, 'email')} className="form-control" type="text" name="ContactForm[email]" placeholder={this.props.intl.formatMessage({id: "Email"})} aria-required="true" value={this.state.email.value} />
                        {this.state.email.error && <p className="help-block help-block-error">{this.props.intl.formatMessage({id: this.state.email.errorMsg})}</p>}
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className={"field " + (this.state.phone.error && "has-error")}>
                        <input onChange={(event) => this.handleChange(event, 'phone')} className="form-control" type="text" name="ContactForm[phone]" placeholder={this.props.intl.formatMessage({id: "Phone"})} aria-required="true" value={this.state.phone.value} />
                        {this.state.phone.error && <p className="help-block help-block-error">{this.props.intl.formatMessage({id: this.state.phone.errorMsg})}</p>}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className={"field " + (this.state.subject.error && "has-error")}>
                        <input onChange={(event) => this.handleChange(event, 'subject')} className="form-control" type="text" name="ContactForm[subject]" placeholder={this.props.intl.formatMessage({id: "Subject"})} aria-required="true" value={this.state.subject.value} />
                        {this.state.subject.error && <p className="help-block help-block-error">{this.props.intl.formatMessage({id: this.state.subject.errorMsg})}</p>}
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className={"field " + (this.state.message.error && "has-error")}>
                        <textarea onChange={(event) => this.handleChange(event, 'message')} className="form-control" type="text" name="ContactForm[message]" placeholder={this.props.intl.formatMessage({id: "Message"})} aria-required="true" value={this.state.message.value} />
                        {this.state.message.error && <p className="help-block help-block-error">{this.props.intl.formatMessage({id: this.state.message.errorMsg})}</p>}
                    </div>
                </div>
            </div>
            <div className="form-trigger">
                <button type="button" onClick={() => this.send()}>{this.props.intl.formatMessage({id: "Send"})}</button>
            </div>
        </form>;
    }
}


export default injectIntl(ContactForm);
